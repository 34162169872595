<template>
  <div class="home-page">
    <HomeBanner />
    <HomeFeatures />
    <HomeAdvantages />
    <HomeCases />
    <HomeContactForm />
  </div>
</template>

<script setup>
import { defineComponent } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';
import HomeFeatures from '@/components/home/HomeFeatures.vue';
import HomeAdvantages from '@/components/home/HomeAdvantages.vue';
import HomeCases from '@/components/home/HomeCases.vue';
import HomeContactForm from '@/components/home/HomeContactForm.vue';

defineComponent({
  name: 'HomePage'
});
</script>

<style scoped>

</style>
