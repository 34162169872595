<template>
  <div class="erp-page">
    <!-- 使用HomeBanner组件显示banner图 -->
    <HomeBanner 
      :images="['/images/erp-banner.png']"
      :autoplay="false"
      :show-arrows="false"
      :show-indicators="false"
      :bg-color="'bg-[#E9F2FD]'"
    />
    <div class="w-full bg-[#F5F8FD]">
      <div class="w-full md:w-[1488px] mx-auto py-8 md:py-16 px-4 md:px-24">
          <p>
            <img src="/images/erp-01.png" alt="业务流程图" class="w-full">
          </p>
      </div>
    </div>
    <div class="w-full bg-[#FFFFFF]">
      <div class="w-full md:w-[1488px] mx-auto py-8 md:py-16 px-4 md:px-24">
          <p>
            <img src="/images/erp-02.png" alt="业务流程图" class="w-full">
          </p>
      </div>
    </div>
    <div class="w-full bg-[#040D2A]">
      <div class="w-full overflow-x-auto flex-shrink-0 flex justify-center">
            <img src="/images/erp-foot.png" class="w-full md:w-auto h-auto">
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';

// 定义组件名称
defineComponent({
  name: 'ErpPage'
});

// 组件逻辑可以在这里添加
</script>

<style scoped>
.erp-page {
  min-height: 100vh;
}

/* 移动端优化 */
@media (max-width: 768px) {
  .erp-page img {
    max-width: 100%;
    height: auto;
  }
}
</style>
