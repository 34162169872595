<template>
  <section class="py-10 md:py-20 bg-white">
    <div class="max-w-full mx-auto px-4">
      <h2 class="text-2xl md:text-3xl font-semibold text-gray-800 text-center mb-8 md:mb-12">采供协同，合作共赢</h2>
      
      <!-- 移动端使用垂直布局，桌面端使用水平滚动布局 -->
      <div class="md:hidden flex flex-col space-y-6">
        <!-- 移动端卡片 -->
        <div v-for="(feature, index) in features" :key="index" 
             class="w-full bg-[#F8F8FA] rounded-lg p-4 relative hover:-translate-y-1 transition-transform duration-300">
          <!-- 右下角图片，放在文字下方 -->
          <div class="absolute bottom-[10px] right-[10px] z-0">
            <img :src="feature.image" :alt="`特性图片${index + 1}`" class="w-full h-full object-cover">
          </div>
          <!-- 文字内容，设置z-index使其在图片上方 -->
          <div class="relative z-10">
            <h3 class="text-lg font-semibold text-gray-800 mb-3 text-center" v-html="feature.title"></h3>
            <p class="text-gray-600 text-sm leading-relaxed mb-4 text-left">{{ feature.description }}</p>
          </div>
        </div>
      </div>
      
      <!-- 桌面端使用水平滚动 -->
      <div class="hidden md:flex justify-center space-x-4 overflow-x-auto pb-4">
        <!-- 第一个卡片 -->
        <div class="w-[268px] h-[390px] bg-[#F8F8FA] rounded-lg p-6 relative hover:-translate-y-1 transition-transform duration-300 flex-shrink-0">
          <div class="flex justify-center items-center mb-5">
          </div>
          <!-- 右下角图片，放在文字下方 -->
          <div class="absolute bottom-[10px] right-[10px] z-0">
            <img src="/images/image@1 (1).png" alt="特性图片1" class="w-full h-full object-cover">
          </div>
          <!-- 文字内容，设置z-index使其在图片上方 -->
          <div class="relative z-10">
            <h3 class="text-lg font-semibold text-gray-800 mb-3 text-center">连接供应链企业<br>构建商业生态圈</h3>
            <p class="text-gray-600 text-sm leading-relaxed mb-4 text-left">现代的商业竞争，已不再是单个企业与企业间的竞争，而是供应链与供应链、产业链与产业链、生态圈与生态圈的竞争。商业平台为您连接供应商、采购商、服务商、代理商、员工、消费者、用户，提升供应链的协作水平与效率。</p>
          </div>
        </div>
        
        <div class="w-[268px] h-[390px] bg-[#F8F8FA] rounded-lg p-6 relative hover:-translate-y-1 transition-transform duration-300 flex-shrink-0">
          <div class="flex justify-center items-center mb-5">
          </div>
          <!-- 右下角图片，放在文字下方 -->
          <div class="absolute bottom-[10px] right-[10px] z-0">
            <img src="/images/image@1 (2).png" alt="特性图片2" class="w-full h-full object-cover">
          </div>
          <!-- 文字内容，设置z-index使其在图片上方 -->
          <div class="relative z-10">
            <h3 class="text-lg font-semibold text-gray-800 mb-3 text-center">一键发送<br>业务单据</h3>
            <p class="text-gray-600 text-sm leading-relaxed mb-4 text-left">一键发送您的采/销订单、收/发货单、收/付款单、对账单等业务单据给供应链伙伴。免去导出、打印、电话、微信、邮件等等的繁琐操作，便捷、及时、准确地将单据传递给客户或供应商。</p>
          </div>
        </div>
        
        <div class="w-[268px] h-[390px] bg-[#F8F8FA] rounded-lg p-6 relative hover:-translate-y-1 transition-transform duration-300 flex-shrink-0">
          <div class="flex justify-center items-center mb-5">
          </div>
          <!-- 右下角图片，放在文字下方 -->
          <div class="absolute bottom-[10px] right-[10px] z-0">
            <img src="/images/image@1 (3).png" alt="特性图片3" class="w-full h-full object-cover">
          </div>
          <!-- 文字内容，设置z-index使其在图片上方 -->
          <div class="relative z-10">
            <h3 class="text-lg font-semibold text-gray-800 mb-3 text-center">一键接收<br>业务单据</h3>
            <p class="text-gray-600 text-sm leading-relaxed mb-4 text-left">一键接收客户或供应商的采/销订单、收/发货单、收/付款单、对账单等业务单据，直接转成您的对应单据。避免人工输入到系统的低效、不及时、易出错等问题。</p>
          </div>
        </div>
        
        <div class="w-[268px] h-[390px] bg-[#F8F8FA] rounded-lg p-6 relative hover:-translate-y-1 transition-transform duration-300 flex-shrink-0">
          <div class="flex justify-center items-center mb-5">
          </div>
          <!-- 右下角图片，放在文字下方 -->
          <div class="absolute bottom-[10px] right-[10px] z-0">
            <img src="/images/image@1 (4).png" alt="特性图片4" class="w-full h-full object-cover">
          </div>
          <!-- 文字内容，设置z-index使其在图片上方 -->
          <div class="relative z-10">
            <h3 class="text-lg font-semibold text-gray-800 mb-3 text-center">自动对接供应链<br>伙伴的业务单据</h3>
            <p class="text-gray-600 text-sm leading-relaxed mb-4 text-left">采供双方的所有业务单据协同对接，共享对方数据。差异信息直观呈现，单据页面直接对话，业务往来高效处理。</p>
          </div>
        </div>
        
        <div class="w-[268px] h-[390px] bg-[#F8F8FA] rounded-lg p-6 relative hover:-translate-y-1 transition-transform duration-300 flex-shrink-0">
          <div class="flex justify-center items-center mb-5">
          </div>
          <!-- 右下角图片，放在文字下方 -->
          <div class="absolute bottom-[10px] right-[10px] z-0">
            <img src="/images/image@1 (5).png" alt="特性图片5" class="w-full h-full object-cover">
          </div>
          <!-- 文字内容，设置z-index使其在图片上方 -->
          <div class="relative z-10">
            <h3 class="text-lg font-semibold text-gray-800 mb-3 text-center">按需共享供应链<br>伙伴的系统数据</h3>
            <p class="text-gray-600 text-sm leading-relaxed mb-4 text-left">有选择的与供应链合作伙伴共享货品信息、库存信息、客户信息和其他业务数据，建立深度、紧密、高效的合作关系，免去了供应链企业重复维护系统基础数据的成本，以及数据不一致导致的转换、整理、沟通的成本与犯错的成本。</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineComponent } from 'vue';

defineComponent({
  name: 'HomeFeatures'
});

// 定义特性数据，用于移动端渲染
const features = [
  {
    title: '连接供应链企业<br>构建商业生态圈',
    description: '现代的商业竞争，已不再是单个企业与企业间的竞争，而是供应链与供应链、产业链与产业链、生态圈与生态圈的竞争。商业平台为您连接供应商、采购商、服务商、代理商、员工、消费者、用户，提升供应链的协作水平与效率。',
    image: '/images/image@1 (1).png'
  },
  {
    title: '一键发送<br>业务单据',
    description: '一键发送您的采/销订单、收/发货单、收/付款单、对账单等业务单据给供应链伙伴。免去导出、打印、电话、微信、邮件等等的繁琐操作，便捷、及时、准确地将单据传递给客户或供应商。',
    image: '/images/image@1 (2).png'
  },
  {
    title: '一键接收<br>业务单据',
    description: '一键接收客户或供应商的采/销订单、收/发货单、收/付款单、对账单等业务单据，直接转成您的对应单据。避免人工输入到系统的低效、不及时、易出错等问题。',
    image: '/images/image@1 (3).png'
  },
  {
    title: '自动对接供应链<br>伙伴的业务单据',
    description: '采供双方的所有业务单据协同对接，共享对方数据。差异信息直观呈现，单据页面直接对话，业务往来高效处理。',
    image: '/images/image@1 (4).png'
  },
  {
    title: '按需共享供应链<br>伙伴的系统数据',
    description: '有选择的与供应链合作伙伴共享货品信息、库存信息、客户信息和其他业务数据，建立深度、紧密、高效的合作关系，免去了供应链企业重复维护系统基础数据的成本，以及数据不一致导致的转换、整理、沟通的成本与犯错的成本。',
    image: '/images/image@1 (5).png'
  }
];
</script>

<style scoped>
/* 隐藏水平滚动条但保留功能 */
.overflow-x-auto::-webkit-scrollbar {
  height: 4px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .overflow-x-auto::-webkit-scrollbar {
    display: none;
  }
  
  .overflow-x-auto {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
</style>
