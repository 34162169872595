<template>
<section class="py-6 md:py-10 bg-white flex justify-center">
  <div class="container mx-auto px-4 max-w-7xl flex flex-col items-center">
    <h1 class="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-16">四化建设，不容错过</h1>
    
    <!-- 移动端布局 -->
    <div class="md:hidden flex flex-col w-full space-y-4">
      <!-- 信息化 -->
      <div class="w-full bg-white border border-[#DCE1F5] flex items-start p-4">
        <div class="bg-blue-500 rounded-full p-3 flex-shrink-0 flex items-center justify-center w-14 h-14">
          <img src="/images/image@icon1.png" alt="信息化" class="w-8 h-8">
        </div>
        <div class="ml-4">
          <h3 class="text-lg font-bold mb-2 relative">
            信息化
            <div class="absolute bottom-[-6px] left-0 w-12 h-[3px] bg-blue-500"></div>
          </h3>
          <p class="text-gray-600 text-sm leading-relaxed">
            让业务流、工作流、资金流、物流、人流全局流通起来，内外贯穿起来。
          </p>
        </div>
      </div>
      
      <!-- 电商化 -->
      <div class="w-full bg-white border border-[#DCE1F5] flex items-start p-4">
        <div class="bg-emerald-500 rounded-full p-3 flex-shrink-0 flex items-center justify-center w-14 h-14">
          <img src="/images/image@icon2.png" alt="电商化" class="w-8 h-8">
        </div>
        <div class="ml-4">
          <h3 class="text-lg font-bold mb-2 relative">
            电商化
            <div class="absolute bottom-[-6px] left-0 w-12 h-[3px] bg-emerald-500"></div>
          </h3>
          <p class="text-gray-600 text-sm leading-relaxed">
            把生意搬到网上，让客户留在网中。用户需求和市场变化情况第一时间掌握，用户服务最直接最高效。
          </p>
        </div>
      </div>
      
      <!-- 互联网化 -->
      <div class="w-full bg-white border border-[#DCE1F5] flex items-start p-4">
        <div class="bg-green-500 rounded-full p-3 flex-shrink-0 flex items-center justify-center w-14 h-14">
          <img src="/images/image@icon3.png" alt="互联网化" class="w-8 h-8">
        </div>
        <div class="ml-4">
          <h3 class="text-lg font-bold mb-2 relative">
            互联网化
            <div class="absolute bottom-[-6px] left-0 w-16 h-[3px] bg-green-500"></div>
          </h3>
          <p class="text-gray-600 text-sm leading-relaxed">
            全面拥抱互联网，跟上时代发展潮流。分享技术革命红利，充分使用云计算、大数据、人工智能。
          </p>
        </div>
      </div>
      
      <!-- 社交化 -->
      <div class="w-full bg-white border border-[#DCE1F5] flex items-start p-4">
        <div class="bg-orange-500 rounded-full p-3 flex-shrink-0 flex items-center justify-center w-14 h-14">
          <img src="/images/image@icon4.png" alt="社交化" class="w-8 h-8">
        </div>
        <div class="ml-4">
          <h3 class="text-lg font-bold mb-2 relative">
            社交化
            <div class="absolute bottom-[-6px] left-0 w-12 h-[3px] bg-orange-500"></div>
          </h3>
          <p class="text-gray-600 text-sm leading-relaxed">
            以社交的方式处理工作和商务，简单、高效、亲和，更具粘性，更具裂变优势。
          </p>
        </div>
      </div>
    </div>
    
    <!-- 桌面端布局 -->
    <div class="hidden md:block relative w-[1420px] h-[476px]">
      <!-- 信息化 -->
      <div class="absolute left-0 top-0 w-[700px] h-[218px] bg-white border border-[#DCE1F5] flex items-start p-8">
        <div class="bg-blue-500 rounded-full p-5 flex-shrink-0 flex items-center justify-center w-20 h-20">
          <img src="/images/image@icon1.png" alt="信息化" class="w-12 h-12">
        </div>
        <div class="ml-6">
          <h3 class="text-xl font-bold mb-3 relative">
            信息化
            <div class="absolute bottom-[-8px] left-0 w-16 h-[3px] bg-blue-500"></div>
          </h3>
          <p class="text-gray-600 leading-relaxed">
            让业务流、工作流、资金流、物流、人流全局流通起来，内外贯穿起来。
          </p>
        </div>
      </div>
      <!-- 电商化 -->
      <div class="absolute left-[720px] top-0 w-[700px] h-[218px] bg-white border border-[#DCE1F5] flex items-start p-8">
        <div class="bg-emerald-500 rounded-full p-5 flex-shrink-0 flex items-center justify-center w-20 h-20">
          <img src="/images/image@icon2.png" alt="电商化" class="w-12 h-12">
        </div>
        <div class="ml-6">
          <h3 class="text-xl font-bold mb-3 relative">
            电商化
            <div class="absolute bottom-[-8px] left-0 w-16 h-[3px] bg-emerald-500"></div>
          </h3>
          <p class="text-gray-600 leading-relaxed">
            把生意搬到网上，让客户留在网中。用户需求和市场变化情况第一时间掌握，用户服务最直接最高效。
          </p>
        </div>
      </div>
      <!-- 互联网化 -->
      <div class="absolute left-0 top-[238px] w-[700px] h-[218px] bg-white border border-[#DCE1F5] flex items-start p-8">
        <div class="bg-green-500 rounded-full p-5 flex-shrink-0 flex items-center justify-center w-20 h-20">
          <img src="/images/image@icon3.png" alt="互联网化" class="w-12 h-12">
        </div>
        <div class="ml-6">
          <h3 class="text-xl font-bold mb-3 relative">
            互联网化
            <div class="absolute bottom-[-8px] left-0 w-20 h-[3px] bg-green-500"></div>
          </h3>
          <p class="text-gray-600 leading-relaxed">
            全面拥抱互联网，跟上时代发展潮流。分享技术革命红利，充分使用云计算、大数据、人工智能。
          </p>
        </div>
      </div>
      <!-- 社交化 -->
      <div class="absolute left-[720px] top-[238px] w-[700px] h-[218px] bg-white border border-[#DCE1F5] flex items-start p-8">
        <div class="bg-orange-500 rounded-full p-5 flex-shrink-0 flex items-center justify-center w-20 h-20">
          <img src="/images/image@icon4.png" alt="社交化" class="w-12 h-12">
        </div>
        <div class="ml-6">
          <h3 class="text-xl font-bold mb-3 relative">
            社交化
            <div class="absolute bottom-[-8px] left-0 w-16 h-[3px] bg-orange-500"></div>
          </h3>
          <p class="text-gray-600 leading-relaxed">
            以社交的方式处理工作和商务，简单、高效、亲和，更具粘性，更具裂变优势。
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script setup>
import { defineComponent } from 'vue';

defineComponent({
  name: 'HomeAdvantages'
});
</script>

<style scoped>
/* 自定义样式 */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 1rem;
  }
}
</style>
