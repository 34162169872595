<template>
  <section :class="['relative overflow-hidden', bgColor]">
    <div class="w-full flex justify-center relative">
      <!-- 轮播图容器 -->
      <div class="w-full overflow-hidden">
        <div class="flex transition-transform duration-500 ease-in-out" :style="{ transform: `translateX(-${activeIndex * 100}%)` }">
          <div v-for="(image, index) in props.images" :key="index" class="w-full flex-shrink-0 flex justify-center">
            <img :src="image" :alt="`轮播图 ${index + 1}`" class="w-full h-auto object-cover md:object-contain">
          </div>
        </div>
      </div>
      
      <!-- 左右箭头导航 -->
      <button 
        v-if="props.showArrows" 
        @click="prevSlide" 
        class="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 bg-white/30 hover:bg-white/50 rounded-full p-1 md:p-2 focus:outline-none z-10"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 md:h-6 md:w-6 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button 
        v-if="props.showArrows" 
        @click="nextSlide" 
        class="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 bg-white/30 hover:bg-white/50 rounded-full p-1 md:p-2 focus:outline-none z-10"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 md:h-6 md:w-6 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
      
      <!-- 指示器 -->
      <div 
        v-if="props.showIndicators" 
        class="absolute bottom-2 md:bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-1 md:space-x-2 z-10"
      >
        <button 
          v-for="(_, index) in props.images" 
          :key="index" 
          @click="setActiveSlide(index)"
          class="w-2 h-2 md:w-3 md:h-3 rounded-full focus:outline-none transition-colors duration-200"
          :class="activeIndex === index ? props.activeIndicatorClass : props.inactiveIndicatorClass"
        ></button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';

defineComponent({
  name: 'HomeBanner'
});

// 定义组件属性
/* eslint-disable-next-line */
const props = defineProps({
  // 轮播图片数组
  images: {
    type: Array,
    default: () => [
      '/images/home-banner-01.png',
      '/images/home-banner-02.png',
      '/images/qyhlxtpt-banner.png'
    ]
  },
  // 自动播放间隔（毫秒）
  autoplayInterval: {
    type: Number,
    default: 5000
  },
  // 是否启用自动播放
  autoplay: {
    type: Boolean,
    default: true
  },
  // 是否显示箭头导航
  showArrows: {
    type: Boolean,
    default: true
  },
  // 是否显示指示器
  showIndicators: {
    type: Boolean,
    default: true
  },
  // 背景颜色
  bgColor: {
    type: String,
    default: 'bg-[#DFEBF7]'
  },
  // 激活指示器的类名
  activeIndicatorClass: {
    type: String,
    default: 'bg-blue-600'
  },
  // 未激活指示器的类名
  inactiveIndicatorClass: {
    type: String,
    default: 'bg-gray-300 hover:bg-gray-400'
  },
  // 是否启用键盘控制
  enableKeyboard: {
    type: Boolean,
    default: true
  }
});

// 当前活动的轮播图索引
const activeIndex = ref(0);

// 切换到下一张轮播图
const nextSlide = () => {
  activeIndex.value = (activeIndex.value + 1) % props.images.length;
};

// 切换到上一张轮播图
const prevSlide = () => {
  activeIndex.value = (activeIndex.value - 1 + props.images.length) % props.images.length;
};

// 设置当前活动的轮播图
const setActiveSlide = (index) => {
  activeIndex.value = index;
};

// 自动轮播定时器
let autoplayTimer = null;

// 启动自动轮播
const startAutoplay = () => {
  if (props.autoplay && props.images.length > 1) {
    autoplayTimer = setInterval(() => {
      nextSlide();
    }, props.autoplayInterval);
  }
};

// 停止自动轮播
const stopAutoplay = () => {
  if (autoplayTimer) {
    clearInterval(autoplayTimer);
    autoplayTimer = null;
  }
};

// 键盘事件处理
const handleKeyDown = (event) => {
  if (!props.enableKeyboard) return;
  
  if (event.key === 'ArrowLeft') {
    prevSlide();
  } else if (event.key === 'ArrowRight') {
    nextSlide();
  }
};

// 组件挂载时启动自动轮播和添加键盘事件监听
onMounted(() => {
  startAutoplay();
  if (props.enableKeyboard) {
    window.addEventListener('keydown', handleKeyDown);
  }
});

// 组件卸载时清理
onUnmounted(() => {
  stopAutoplay();
  if (props.enableKeyboard) {
    window.removeEventListener('keydown', handleKeyDown);
  }
});

// 暴露方法给父组件
/* eslint-disable-next-line */
defineExpose({
  nextSlide,
  prevSlide,
  setActiveSlide,
  activeIndex
});
</script>

<style scoped>
/* 轮播图样式 */
.transition-transform {
  will-change: transform;
}
</style>
