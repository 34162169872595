<template>
  <div class="platform-page">
    <!-- 使用HomeBanner组件显示banner图 -->
    <HomeBanner 
      :images="['/images/gylxt-banner.png']"
      :autoplay="false"
      :show-arrows="false"
      :show-indicators="false"
    />
    <div class="w-full bg-[#F5F8FD]">
      <div class="min-h-[300px] md:min-h-[500px] w-full md:w-[1440px] mx-auto py-8 md:py-16 px-4 md:px-24">
        <div class="flex flex-col md:flex-row md:items-center gap-6 md:gap-8">
          <!-- 左侧功能图 -->
          <div class="w-full md:w-2/5">
            <img src="/images/gylxt-01.png" class="w-full">
          </div>
          <!-- 右侧文案 -->
          <div class="w-full md:w-2/3 space-y-6 md:space-y-8">
            <h2 class="text-xl md:text-2xl font-bold text-center">产品概述</h2>
            <p class="text-sm md:text-base text-gray-600 leading-relaxed">
              商栈供应链协同是商栈平台的基础应用与核心功能，致力于为广大中小企业与其供应链企业之间建立紧密合作、高效协同的伙伴关系并提供相关的协同工具，主要由企业协同、货品协同、价格协同、业务协同、数据协同以及消息协同等部分组成。
            </p>
            <p class="text-sm md:text-base text-gray-600 leading-relaxed">
              现在市面上所谓的供应链协同系统或供应链管理系统基本上都是进销存系统的一个别称而已，既不符合以上基本特点，也没实现以上核心价值。
            </p>
            
            <!-- 特点和价值部分 -->
            <div class="mt-6 md:mt-10 space-y-6 md:space-y-8">
              <!-- 基本特点 -->
              <div class="flex items-start">
                <div class="flex-shrink-0 mr-3 md:mr-4">
                  <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">1</div>
                </div>
                <div>
                  <h3 class="text-base md:text-lg font-medium mb-1 md:mb-2">商栈供应链协同的基本特点：</h3>
                  <p class="text-sm md:text-base text-gray-600">连接企业、共享数据、同步业务。</p>
                </div>
              </div>
              
              <!-- 核心价值 -->
              <div class="flex items-start">
                <div class="flex-shrink-0 mr-3 md:mr-4">
                  <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">2</div>
                </div>
                <div>
                  <h3 class="text-base md:text-lg font-medium mb-1 md:mb-2">商栈供应链协同的核心价值：</h3>
                  <p class="text-sm md:text-base text-gray-600">业务往来即时触发、业务内容准确一致、业务操作便捷高效。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-white">
      <div class="min-h-[400px] md:min-h-[800px] w-full md:w-[1440px] mx-auto">
        <div class="container mx-auto px-4 md:px-8 py-8 md:py-12">
          <h1 class="text-xl md:text-2xl font-bold text-center mb-6 md:mb-12">主要模块</h1>
          <!-- 模块导航 - 移动端改为滚动列表 -->
          <div class="flex overflow-x-auto md:justify-center items-center gap-4 md:gap-8 mx-auto max-w-full md:max-w-5xl mb-6 md:mb-12 pb-2 md:pb-0">
            <div v-for="(item, index) in modules" 
                 :key="index" 
                 class="group cursor-pointer flex items-center flex-shrink-0"
                 @click="activeModuleIndex = index">
              <div class="rounded-full w-10 h-10 md:w-14 md:h-14 flex items-center justify-center mr-2 md:mr-3 bg-blue-600">
                <i class="fas text-lg md:text-2xl text-white" :class="[item.icon]"></i>
              </div>
              <p class="font-medium text-sm md:text-base whitespace-nowrap" 
                 :class="activeModuleIndex === index ? 'text-blue-600' : 'text-gray-500'">
                {{ item.title }}
              </p>
            </div>
          </div>
          
          <!-- 模块详情内容展示区域 -->
          <div class="mt-6 md:mt-12 transition-all duration-500 ease-in-out">
            <!-- 企业协同 -->
            <div v-if="activeModuleIndex === 0" class="w-full">
              <div class="w-full text-center mb-6 md:mb-10 text-sm md:text-base">
                通过类似微信加好友的方式，与客户、供应商、服务商、代理商、消费者等建立协同关系，形成自己的企业朋友圈
              </div>
              <img src="/images/scm-menu-01.png" class="w-full">
            </div>
            
            <!-- 货品协同 -->
            <div v-if="activeModuleIndex === 1" class="w-full">
              <div class="flex flex-col md:flex-row md:justify-between mb-6 md:mb-10 gap-4 md:gap-8">
                <div class="flex items-start w-full md:w-1/2">
                  <div class="flex-shrink-0 mr-3 md:mr-4">
                    <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">1</div>
                  </div>
                  <div>
                    <h3 class="text-base md:text-lg font-medium mb-2 md:mb-3 text-black">通过将本方货品共享给对方或将对方货品引用为本方货品的方式实现货品的共享共用。</h3>
                    <p class="text-xs md:text-sm text-gray-600">比如A企业将本方的A001货品共享给B企业，那么B企业在业务单据中就可以选择使用A001</p>
                  </div>
                </div>
                
                <div class="flex items-start w-full md:w-1/2 mt-4 md:mt-0">
                  <div class="flex-shrink-0 mr-3 md:mr-4">
                    <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">2</div>
                  </div>
                  <div>
                    <h3 class="text-base md:text-lg font-medium mb-2 md:mb-3 text-black">通过建立不同货品之间的对应转换关系实现各种业务场景下货品的识别与转换。</h3>
                    <p class="text-xs md:text-sm text-gray-600">比如B企业将A企业的A001货品与本方的B001货品建立了对应转换关系，那么当A企业给B企业推送的业务单据里面含有A001货品的时候，B企业就可以自动识别其与本方的B001货品存在对应关系，并默认将其转换为B001货品。</p>
                  </div>
                </div>
              </div>
              
              <div class="w-full mt-4 md:mt-9">
                <img src="/images/scm-menu-02.png" class="w-full">
              </div>
              
            </div>
            
            <!-- 价格协同 -->
            <div v-if="activeModuleIndex === 2" class="w-full">
              <div class="w-full text-center mb-6 md:mb-10 text-sm md:text-base">
                通过一方发起定价单协同，另一方接受价格单协同的方式，实现双方对货品价格的共同确认。共同确认后的货品价格会一致体现在双方的业务单据中。
              </div>
              <img src="/images/scm-menu-03.png" class="w-full">
            </div>
            
            <!-- 业务协同 -->
            <div v-if="activeModuleIndex === 3" class="w-full">
              <div class="w-full md:w-[1000px] mx-auto text-center mb-6 md:mb-10 text-sm md:text-base">
                通过一方发起业务单据协同，另一方接受业务单据协同的方式，实现双方对业务单据内容的共同确认。共同确认后的业务单据内容会一致体现在双方的对账业务中。
这里指的业务单据主要有采销订单、收发货单、收付款单等。
              </div>
                <div class="w-full">
                  <img src="/images/scm-menu-04.png" alt="业务协同流程图" class="w-full">
              </div>
            </div>
            
            <!-- 数据协同 -->
            <div v-if="activeModuleIndex === 4" class="w-full">
              <div class="w-full md:w-[1000px] mx-auto text-center mb-6 md:mb-10 text-sm md:text-base">
                根据需要，企业可以将本方的数据报表共享给任何一家供应链企业，以实现供应链企业之间的精准采销与高效决策。
举例：A企业将本方部分货品的库存数据共享给供应商B企业，B企业据此给A企业及时补货，以确保A企业保持合理的库存水平。
              </div>
              <div class="w-full">
                <img src="/images/scm-menu-05.png" alt="数据协同流程图" class="w-full">
              </div>
            </div>
            
            <!-- 消息协同 -->
            <div v-if="activeModuleIndex === 5" class="w-full">
              <div class="flex flex-col md:flex-row md:justify-between mb-6 md:mb-10 gap-4 md:gap-8">
                <div class="flex items-start w-full md:w-1/2">
                  <div class="flex-shrink-0 mr-3 md:mr-4">
                    <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">1</div>
                  </div>
                  <div>
                    <p class="text-xs md:text-sm text-gray-600">供应链伙伴之间任何一方新建或更新协同业务，都会自动给对方推送一条协同消息，方便对方及时了解并介入相关业务的处理。比如A企业在系统里新做了或更新了一张供应商为B企业的采购订单，B企业都会即时收到相关消息，点击该消息可打开相应的单据进行查阅或处理。</p>
                  </div>
                </div>
                
                <div class="flex items-start w-full md:w-1/2 mt-4 md:mt-0">
                  <div class="flex-shrink-0 mr-3 md:mr-4">
                    <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">2</div>
                  </div>
                  <div>
                    <p class="text-xs md:text-sm text-gray-600">采供双方可以在业务单据中就本单的相关事项进行即时聊天式的留言互动，这些留言并可精确关联到具体的货品明细，而且既可以在单据页面互动，也可以在统一的聊天窗中互动。</p>
                  </div>
                </div>
              </div>
              
              <div class="w-full">
                <img src="/images/scm-menu-06.png" alt="消息协同流程图" class="w-full">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-[#F5F8FD]">
      <div class="min-h-[400px] md:min-h-[1024px] w-full md:w-[1440px] mx-auto py-8 md:py-16 px-4 md:px-24">
          <h1 class="text-2xl md:text-4xl font-bold text-center mb-6 md:mb-8">业务流程</h1>
          <p>
            <img src="/images/scm-flow.png" alt="业务流程图" class="w-full">
          </p>
      </div>
    </div>
    <div class="w-full bg-[#FFFFFF]">
      <div class="min-h-[300px] md:min-h-[661px] w-full md:w-[1440px] mx-auto mt-3 px-4 md:px-24">
          <p>
            <img src="/images/scm-foot-01.png" class="w-full">
          </p>
      </div>
    </div>
    <div class="w-full bg-[#F5F8FD]">
      <div class="min-h-[400px] md:min-h-[810px] w-full md:w-[1440px] mx-auto py-4 md:py-8 px-4 md:px-24">
        <p>
            <img src="/images/scm-foot-02.png" class="w-full">
          </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, ref } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';

defineComponent({
  name: 'ScmPage'
});

// 定义模块类型
const modules = ref([
  { title: '企业协同', icon: 'fa-building' },
  { title: '货品协同', icon: 'fa-box' },
  { title: '价格协同', icon: 'fa-tag' },
  { title: '业务协同', icon: 'fa-handshake' },
  { title: '数据协同', icon: 'fa-chart-bar' },
  { title: '消息协同', icon: 'fa-comment' },
]);

// 当前激活的模块索引
const activeModuleIndex = ref(0);
</script>

<style scoped>
.platform-page {
  min-height: 100vh;
}

/* 确保移动端模块导航可以横向滚动 */
@media (max-width: 768px) {
  .overflow-x-auto::-webkit-scrollbar {
    display: none;
  }
  
  .overflow-x-auto {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
</style>
