<template>
  <header class="fixed top-0 left-0 w-full h-16 bg-white shadow-md z-50">
    <div class="max-w-7xl mx-auto px-4 h-full flex items-center justify-between">
      <!-- 左侧LOGO -->
      <div class="flex items-center">
        <img src="/images/logo.png" alt="Logo" class="w-[65px] h-[40px]">
      </div>
      
      <!-- 中间按钮 - 仅在移动端显示 -->
      <div class="flex md:hidden space-x-2 justify-center">
        <a href="https://erp.demo.shangzhanyun.cn/login" target="_blank" class="px-2 py-1 bg-gray-100 text-gray-700 rounded-md text-xs hover:bg-gray-200 transition-colors">免费体验</a>
        <a href="https://erp.shangzhanyun.com/login" target="_blank" class="px-2 py-1 bg-blue-600 text-white rounded-md text-xs hover:bg-blue-700 transition-colors">立即使用</a>
      </div>
      
      <!-- 桌面端导航 -->
      <nav class="hidden md:flex space-x-14 ml-20 h-16">
        <router-link to="/" class="text-base text-gray-700 hover:text-blue-600 transition-colors relative flex items-center h-full nav-link-hover" :class="{'active': $route.path === '/'}">首页</router-link>
        
        <!-- 产品介绍下拉菜单 -->
        <div class="relative group h-full">
          <a href="#" class="text-base text-gray-700 group-hover:text-blue-600 transition-colors relative flex items-center h-full nav-link-hover">
            产品介绍
          </a>
          <!-- 下拉菜单 -->
          <div class="absolute left-0 top-full w-40 bg-white shadow-lg rounded-b-md overflow-hidden opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
            <router-link to="/platform" class="block py-2 px-4 font-medium transition-colors nav-link" 
              :class="$route.path === '/platform' ? 'active' : ''">
              企业互联协同平台
            </router-link>
            <router-link to="/scm" class="block py-2 px-4 font-medium transition-colors nav-link" 
              :class="$route.path === '/scm' ? 'active' : ''">
              供应链协同
            </router-link>
            <router-link to="/order" class="block py-2 px-4 font-medium transition-colors nav-link" 
              :class="$route.path === '/order' ? 'active' : ''">
              订货系统
            </router-link>
            <router-link to="/erp" class="block py-2 px-4 font-medium transition-colors nav-link" 
              :class="$route.path === '/erp' ? 'active' : ''">
              进销存系统
            </router-link>
          </div>
        </div>
        <router-link to="/buy" class="text-base text-gray-700 hover:text-blue-600 transition-colors relative flex items-center h-full nav-link-hover" :class="{'active': $route.path === '/buy'}">购买服务</router-link>
        <router-link to="/about" class="text-base text-gray-700 hover:text-blue-600 transition-colors relative flex items-center h-full nav-link-hover" :class="{'active': $route.path === '/about'}">关于我们</router-link>
      </nav>
      
      <!-- 右侧区域 -->
      <div class="flex items-center">
        <!-- 桌面端按钮 -->
        <div class="hidden md:flex space-x-2">
          <a href="https://erp.demo.shangzhanyun.cn/login" target="_blank" class="px-4 py-2 bg-gray-100 text-gray-700 rounded-md text-sm hover:bg-gray-200 transition-colors">免费体验</a>
          <a href="https://erp.shangzhanyun.com/login" target="_blank" class="px-4 py-2 bg-blue-600 text-white rounded-md text-sm hover:bg-blue-700 transition-colors">立即使用</a>
        </div>
        
        <!-- 移动端菜单按钮 -->
        <button 
          @click="isMobileMenuOpen = !isMobileMenuOpen" 
          class="md:hidden flex items-center justify-center focus:outline-none"
          aria-label="菜单">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path v-if="!isMobileMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
    
    <!-- 移动端抽屉式导航 -->
    <transition name="drawer">
      <div v-if="isMobileMenuOpen" class="md:hidden">
        <div 
          class="fixed inset-0 bg-black bg-opacity-50 z-40"
          @click="isMobileMenuOpen = false"
        ></div>
        <div 
          class="fixed top-16 left-0 w-full bg-white z-40 shadow-lg transform"
        >
          <div class="py-4 px-6 space-y-4">
            <router-link 
              to="/" 
              class="block py-2 border-b border-gray-100 text-gray-800"
              :class="{'text-blue-600 font-medium': $route.path === '/'}"
              @click="isMobileMenuOpen = false"
            >
              首页
            </router-link>
            
            <!-- 移动端产品介绍折叠面板 -->
            <div>
              <div 
                @click="isProductMenuOpen = !isProductMenuOpen"
                class="flex justify-between items-center py-2 border-b border-gray-100 text-gray-800 cursor-pointer"
              >
                <span>产品介绍</span>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  class="h-4 w-4 transition-transform" 
                  :class="isProductMenuOpen ? 'rotate-180' : ''"
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              <div v-if="isProductMenuOpen" class="pl-4 py-2 space-y-2 bg-gray-50">
                <router-link 
                  to="/platform" 
                  class="block py-1 text-gray-700 text-sm" 
                  :class="{'text-blue-600 font-medium': $route.path === '/platform'}"
                  @click="isMobileMenuOpen = false"
                >
                  企业互联协同平台
                </router-link>
                <router-link 
                  to="/scm" 
                  class="block py-1 text-gray-700 text-sm" 
                  :class="{'text-blue-600 font-medium': $route.path === '/scm'}"
                  @click="isMobileMenuOpen = false"
                >
                  供应链协同
                </router-link>
                <router-link 
                  to="/order" 
                  class="block py-1 text-gray-700 text-sm" 
                  :class="{'text-blue-600 font-medium': $route.path === '/order'}"
                  @click="isMobileMenuOpen = false"
                >
                  订货系统
                </router-link>
                <router-link 
                  to="/erp" 
                  class="block py-1 text-gray-700 text-sm" 
                  :class="{'text-blue-600 font-medium': $route.path === '/erp'}"
                  @click="isMobileMenuOpen = false"
                >
                  进销存系统
                </router-link>
              </div>
            </div>
            
            <router-link 
              to="/buy" 
              class="block py-2 border-b border-gray-100 text-gray-800"
              :class="{'text-blue-600 font-medium': $route.path === '/buy'}"
              @click="isMobileMenuOpen = false"
            >
              购买服务
            </router-link>
            <router-link 
              to="/about" 
              class="block py-2 border-b border-gray-100 text-gray-800"
              :class="{'text-blue-600 font-medium': $route.path === '/about'}"
              @click="isMobileMenuOpen = false"
            >
              关于我们
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script setup>
import { ref } from 'vue';

// 移动端菜单状态
const isMobileMenuOpen = ref(false);
// 产品介绍子菜单状态
const isProductMenuOpen = ref(false);
</script>

<style scoped>
/* 确保下拉菜单在hover时保持可见 */
.group:hover .group-hover\:visible {
  visibility: visible;
}

.nav-link {
  position: relative;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.nav-link:hover {
  color: #2563eb; /* hover:text-blue-600 */
}

/* 顶部导航激活样式 */
.nav-link-hover.active {
  color: #2563eb !important; /* text-blue-600 */
}

.nav-link-hover.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2563eb; /* bg-blue-600 */
}

/* 抽屉动画 */
.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.3s ease;
}

.drawer-enter-from,
.drawer-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
