<template>
  <div>
    <!-- 使用HomeBanner组件显示banner图 -->
    <HomeBanner :images="['/images/buy-banner.png']" :autoplay="false" :show-arrows="false"
      :show-indicators="false" :bg-color="'bg-[#E9F2FD]'" />
    <div class="bg-gray-50 py-6 md:py-12">
      <div class="mx-auto max-w-7xl px-4">
        <div class="grid gap-4 md:gap-8">
          <!-- 在线用户名额 -->
          <div class="bg-white rounded-lg shadow-sm p-4 md:p-6">
            <div class="flex flex-col md:flex-row md:justify-between md:items-start">
              <div class="flex items-start gap-3 md:gap-4">
                <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-orange-100 flex items-center justify-center flex-shrink-0">
                  <i class="fas fa-user text-xl md:text-2xl text-orange-500"></i>
                </div>
                <div class="flex-1">
                  <h2 class="text-lg md:text-xl font-medium mb-2">在线用户名额
                    <div class="h-1 w-16 md:w-32 bg-orange-500 mt-1"></div>
                  </h2>
                  <div class="text-gray-600 mt-2 md:mt-4">
                    <!-- <p>
                      <span>当前在线用户名额：{{ onlineUsers }}个，其中付费名额{{ paidUsers }}个，赠送名额{{ freeUsers }}个</span>
                    </p> -->
                    <p class="mt-2 text-xs md:text-sm text-gray-500 max-w-3xl">在线用户名额指当前企业可以同时登录商栈的用户数量限额，与注册用户数量无关。</p>
                  </div>
                </div>
              </div>
              <div class="mt-4 md:mt-0 px-3 md:px-4 py-2 rounded-lg flex-shrink-0 md:ml-4 flex flex-row md:flex-col items-center md:items-end justify-between">
                <span class="text-orange-500 text-xl md:text-2xl font-medium">¥ {{ price }}/人/年</span>
                <div class="md:mt-4">
                <button @click="handleBuy"
                  class="bg-orange-500 hover:bg-orange-600 text-white px-4 md:px-6 py-1.5 md:py-2 !rounded-button whitespace-nowrap shadow-none text-sm md:text-base">
                  <i class="fas fa-shopping-cart mr-2"></i>购买
                </button>
              </div>
              </div>
            </div>
          </div>
          <!-- 企业协同平台 -->
          <div class="bg-white rounded-lg shadow-sm p-4 md:p-6">
            <div class="flex flex-col md:flex-row md:justify-between md:items-start">
              <div class="flex items-start gap-3 md:gap-4">
                <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                  <i class="fas fa-building text-xl md:text-2xl text-blue-500"></i>
                </div>
                <div class="flex-1">
                  <h2 class="text-lg md:text-xl font-medium mb-2">企业协同平台
                    <div class="h-1 w-16 md:w-32 bg-blue-500 mt-1"></div>
                  </h2>
                  <!-- <p class="text-gray-500 mb-2">到期时间：{{ expiryDate1 }}</p> -->
                  <p class="text-sm md:text-base text-gray-600 max-w-3xl">
                    小程序+PC端。实现业务单据及其关联信息在采供双方系统之间的直接同步传递，让企业间下单、接单、跟单、对账、结算的过程更加便捷、及时、准确。无缝对接仓储管理系统，内外部业务一站式、一体化高效协同处理。
                  </p>
                </div>
              </div>
              <div class="mt-4 md:mt-0 bg-gray-50 px-3 md:px-4 py-2 rounded-lg flex-shrink-0 md:ml-4 self-start md:self-auto">
                <span class="text-blue-500 text-lg md:text-xl font-medium">免费</span>
              </div>
            </div>
          </div>
          <!-- 仓储管理系统 -->
          <div class="bg-white rounded-lg shadow-sm p-4 md:p-6">
            <div class="flex flex-col md:flex-row md:justify-between md:items-start">
              <div class="flex items-start gap-3 md:gap-4">
                <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0">
                  <i class="fas fa-warehouse text-xl md:text-2xl text-green-500"></i>
                </div>
                <div class="flex-1">
                  <h2 class="text-lg md:text-xl font-medium mb-2">仓储管理系统
                    <div class="h-1 w-16 md:w-32 bg-green-500 mt-1"></div>
                  </h2>
                  <!-- <p class="text-gray-500 mb-2">到期时间：{{ expiryDate2 }}</p> -->
                  <p class="text-sm md:text-base text-gray-600 max-w-3xl">小程序+PC端。承接企业协同平台的后端仓储处理业务，直接生成出入库单据。支持盘点与调整。流程高效，操作简单，功能完整，报表实用。
                  </p>
                </div>
              </div>
              <div class="mt-4 md:mt-0 bg-gray-50 px-3 md:px-4 py-2 rounded-lg flex-shrink-0 md:ml-4 self-start md:self-auto">
                <span class="text-green-500 text-lg md:text-xl font-medium">免费</span>
              </div>
            </div>
          </div>
          <div class="relative">
            <!-- 纸飞机背景图 -->
            <div class="paper-plane-bg hidden md:block" :style="{
              backgroundImage: `url(${paperPlaneUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            }"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, ref } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';

// 定义组件名称
defineComponent({
  name: 'BuyPage'
});
// const onlineUsers = ref(1);
// const paidUsers = ref(0);
// const freeUsers = ref(1);
const price = ref(980);
// const expiryDate1 = ref('2025-10-15');
// const expiryDate2 = ref('2025-10-15');
const paperPlaneUrl = '/images/paper-plane.png';
// 组件逻辑可以在这里添加
const handleBuy = () => {
  console.log('购买');
  // TODO: 弹出跳转到购买页面
  window.open('https://erp.shangzhanyun.com/tenantApp/tenantApp/index', '_blank');
};
</script>

<style scoped>
.paper-plane-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -20px;
  left: -20px;
  transform: rotate(-15deg);
  opacity: 0.2;
  z-index: 0;
}

.text-lg {
  text-align: justify;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .paper-plane-bg {
    display: none;
  }
}
</style>
