<template>
  <div class="about-page">
    <!-- 使用HomeBanner组件显示banner图 -->
    <HomeBanner 
      :images="['/images/about-banner.png']"
      :autoplay="false"
      :show-arrows="false"
      :show-indicators="false"
      :bg-color="'bg-[#E9F2FD]'"
    />
    <div class="w-full bg-[#FFFFFF]">
      <div class="w-full md:w-[1440px] mx-auto py-6 md:py-16 px-4 md:px-24">
        <div class="flex flex-col items-center">
          <h1 class="text-xl md:text-3xl font-bold text-center mb-6 md:mb-12">企业介绍</h1>
          <div class="w-full md:w-[1000px] px-4 md:px-0">
            <!-- 公司简介 -->
             <p class="text-sm md:text-base mb-4">
              · 上海奔云信息科技有限公司成立于2016年，致力于以PAAS（平台即服务）和SAAS（软件即服务）的模式，构建一个为中小微企业提供营销、管理和资源服务的综合性运营平台， 并创造性地提出了<b>"企业互联网"</b>和<b>"商业生态圈"</b>的理念：通过连接企业间的业务流、工作流、物流、资金流和人流，组成万企互联的企业互联网，不断丰富各种企业服务工具、引入企业运营所需的各类资源，形成一个可以<b>协同共生、循环发展的商业生态圈</b>。
            </p>
            <p class="text-sm md:text-base mb-4">
· 奔云团队的核心成员有着丰富的实体企业经营经验和互联网行业从业经验。我们本着<b>"聚商成栈 奔驰云上"</b>的使命，结合国内商业环境和中小微企业的实际，做了很多创新性的探索。在此过程中，产品、架构和代码几番推倒重来，历尽艰辛。
</p>
<p class="text-sm md:text-base">
· <b>产品没有完美，服务没有止境</b>。我们将尽最大努力为广大中小企业构建便捷、高效、紧密的企业互联协同平台，让每家企业都可以通过连接自己的上下游企业，协同作业、高效互动，提升供应链竞争优势，打造属于自己的商业生态圈。
</p>     
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-[#F8F8FA]">
      <div class="w-full flex-shrink-0 flex justify-center overflow-hidden">
        <img src="/images/about-01.png" class="w-full md:w-auto h-auto object-cover">
      </div>
    </div>
    <div class="bg-white">
    <div class="w-full md:w-[1200px] mx-auto px-4 md:px-8 py-6 md:py-8">
      <div class="relative">
        <!-- 纸飞机背景图 -->
        <div class="paper-plane-bg hidden md:flex" :style="{
          backgroundImage: `url(${paperPlaneUrl})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }"></div>
        
        <!-- 主要内容 -->
        <h1 class="text-2xl md:text-4xl font-bold text-center mb-8 md:mb-16">致合作伙伴</h1>
        
        <div class="space-y-4 md:space-y-8 text-gray-800 leading-relaxed">
          <p class="text-base md:text-lg">
            <b>  夫商业之本，不外乎价值创造，经营之道，实质是合作共赢。</b>
        </p>
        <p class="text-base md:text-lg">
            在内而言，合作提高效率。于外来说，合作促成生意。效率决定成本，生意创造收入。此既价值之所在，亦利润之来源也。
            </p>
            <p class="text-base md:text-lg">   
            合作之成，必先连接。连接各方商业主体，从而结成商业伙伴。连接不同系统工具，自此消除协同障碍。基于互联互通，共成商业生态。生态之利，在于全方位赋能，大规模协作。拥有生态，犹鱼入大海。失去生态，如虎落平阳。荒漠之中，生存维艰。繁华都市，应有尽有。
            </p>
            <p class="text-base md:text-lg">
            吾辈有幸，生于斯万物互联时代。凡事都可想象，一切皆有可能。怀大志者不妨纵横捭阖，谋小成者亦可游刃有余。因为合作如此容易，生态这般丰富。
            </p>
            <p class="text-base md:text-lg">
            人言商场如战场。实则竞中有合，合中有竞。合者多，竞者少，必立于不败之地。合者少，竞者多，难免成众矢之的。一切皆生态也，一切亦商道也。
            </p>
            <p class="text-base md:text-lg">
              <b>"商栈"者，"聚商成栈"</b>之意也。本乎商业之道，开辟互联平台，构建商业生态。<b>提供各类企业应用，聚合丰富商业资源</b>。致力于<b>让天下没有难做的企业</b>。
            </p>
            <p class="text-base md:text-lg">
            <b>然则商业平台众多，企业应用颇丰。商栈后起，优势何在？</b>
            </p>
            <p class="text-base md:text-lg">
            曰：今之商业平台，或C端，或B端；或垂直，或综合。所连者，供应链之一段，产业链之一隅，而无全商业之闭环。今之企业应用，或营销，或管理；或手机，或PC。所用者，唯工具之刻板，兼系统之封闭，不见全信息之融通。用者困守信息之孤岛，徒叹生态之荒芜。C端项目，模仿容易，致投机者接踵。B端项目，必须深耕，而恒守者寥寥。纵贯商业全程、横连行业全局，无边界互联互通者，未见有也。
            </p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>
import { defineComponent } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';

// 定义组件名称
defineComponent({
  name: 'AboutPage'
});
const paperPlaneUrl = '/images/paper-plane.png';
// 组件逻辑可以在这里添加
</script>

<style scoped>
.about-page {
  min-height: 100vh;
}
.text-base, .text-lg {
  text-align: justify;
  letter-spacing: 0.5px;
}

.paper-plane-bg {
  position: absolute;
  right: -100px;
  top: 50px;
  width: 358.47px;
  height: 200.96px;
  transform: rotate(-15deg);
  opacity: 0.2;
  z-index: 0;
}

@media (max-width: 768px) {
  .text-base, .text-lg {
    letter-spacing: 0.3px;
  }
}
</style>
