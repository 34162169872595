<!-- 代码已包含 CSS：使用 TailwindCSS , 安装 TailwindCSS 后方可看到布局样式效果 -->
<template>
  <footer class="w-full bg-[#040B1C] text-white py-8 md:py-12">
  <div class="max-w-[1440px] mx-auto px-4 md:px-8">
    <!-- 移动端布局 -->
    <div class="block md:hidden space-y-8">
      <!-- 品牌区域 -->
      <div class="flex flex-col items-center text-center">
        <div class="text-2xl font-bold mb-4">
          <img src="/images/logo01.png" alt="商栈" class="h-10">
        </div>
        <h2 class="text-base font-medium mb-2">让天下没有难做的企业</h2>
        <div class="text-xs text-gray-400 space-y-1">
          <p>聚商成长 奔驰云上</p>
          <p>无边界企业互联网 大协同商业生态圈</p>
        </div>
      </div>
      
      <!-- 二维码区域 -->
      <div class="flex justify-center gap-8">
        <div class="flex flex-col items-center">
          <p class="mb-2 text-xs">商栈·小程序</p>
          <div class="w-24 h-24 bg-white p-1">
            <img src="/images/erweima_1.89e71a2e@1x.png"
                alt="小程序二维码"
                class="w-full h-full object-cover"/>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <p class="mb-2 text-xs">商栈·公众号</p>
          <div class="w-24 h-24 bg-white p-1">
            <img src="/images/erweima_2.d6c5136c@1x.png"
                alt="公众号二维码"
                class="w-full h-full object-cover"/>
          </div>
        </div>
      </div>
      
      <!-- 联系区域 -->
      <div class="flex flex-col items-center text-center">
        <div class="flex items-center mb-4">
          <div class="w-8 h-8 rounded-full bg-white flex items-center justify-center mr-2">
            <img src="/images/kficon.png" alt="联系图标" class="w-5 h-5">
          </div>
          <span class="text-base">联系我们</span>
        </div>
        <div class="text-xs text-gray-400 space-y-1">
          <p>扫描上方二维码，联系我们</p>
          <p>地址：上海市青浦区崧雅路166弄1号楼1507</p>
        </div>
      </div>
    </div>

    <!-- 桌面端布局 -->
    <div class="hidden md:flex justify-between items-start">
      <!-- 左侧品牌区域 -->
      <div class="flex flex-col">
        <div class="text-3xl font-bold mb-6">
          <img src="/images/logo01.png" alt="商栈">
        </div>
        <h2 class="text-lg font-medium mb-3">让天下没有难做的企业</h2>
        <div class="text-sm text-gray-400 space-y-1">
          <p>聚商成长 奔驰云上</p>
          <p>无边界企业互联网 大协同商业生态圈</p>
        </div>
      </div>
      
      <!-- 中间联系区域 -->
      <div class="flex items-start">
        <div class="flex flex-col items-start">
          <div class="flex items-center mb-6">
            <div class="w-10 h-10 rounded-full bg-white flex items-center justify-center mr-3">
              <img src="/images/kficon.png" alt="联系图标">
            </div>
            <span class="text-lg">联系我们</span>
          </div>
          <div class="text-sm text-gray-400 space-y-2 pl-[52px] -mt-4">
            <p>扫描右侧：</p>
            <p>公众号二维码，联系我们</p>
            <p>小程序二维码，体验产品</p>
            <p class="mt-4">地址：上海市青浦区崧雅路166弄1号楼1507</p>
          </div>
        </div>
      </div>
      
      <!-- 右侧二维码区域 -->
      <div class="flex gap-20">
        <div class="flex flex-col items-center">
          <p class="mb-4 text-sm">商栈·小程序</p>
          <div class="w-32 h-32 bg-white p-1">
            <img src="/images/erweima_1.89e71a2e@1x.png"
                alt="小程序二维码"
                class="w-full h-full object-cover"/>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <p class="mb-4 text-sm">商栈·公众号</p>
          <div class="w-32 h-32 bg-white p-1">
            <img src="/images/erweima_2.d6c5136c@1x.png"
                alt="公众号二维码"
                class="w-full h-full object-cover"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- 底部版权信息 -->
  <div class="text-center text-xs text-gray-500 mt-6 md:mt-8 pt-6 md:pt-8 border-t border-gray-800 px-4">
    软件企业编号：沪CR-2013-0914 | 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16016473号</a> | 2025 上海奔云信息科技有限公司
  </div>
</footer>
</template>
<script setup>
</script>
<style scoped>
</style>