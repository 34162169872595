import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/home/HomePage.vue'
import PlatformPage from '@/views/platform/PlatformPage.vue'
import ScmPage from '@/views/scm/ScmPage.vue'
import OrderPage from '@/views/order/OrderPage.vue'
import ErpPage from '@/views/erp/ErpPage.vue'
import AboutPage from '@/views/about/AboutPage.vue'
import BuyPage from '@/views/buy/BuyPage.vue'

const routes = [
  {
    path: '/',
    name: '商栈',
    component: HomePage
  },
  {
    path: '/platform',
    name: '企业互联协同平台',
    component: PlatformPage
  },
  {
    path: '/scm',
    name: '供应链协同',
    component: ScmPage
  },
  {
    path: '/order',
    name: '订货系统',
    component: OrderPage
  },
  {
    path: '/erp',
    name: '仓储管理系统',
    component:  ErpPage
  },
  {
    path: '/about',
    name: '关于我们',
    component:  AboutPage
  },
  {
    path: '/buy',
    name: '购买服务',
    component:  BuyPage
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局前置守卫，在导航触发时更新浏览器标题
router.beforeEach((to, from, next) => {
  // 将页面标题设置为路由的name
  let title = to.name;
  if (title !== '商栈') {
    title += ' - 商栈';
  }
  document.title = title;
  next();
})

export default router
