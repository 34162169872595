<template>
  <div class="order-page">
    <!-- 使用HomeBanner组件显示banner图 -->
    <HomeBanner 
      :images="['/images/order-banner.png']"
      :autoplay="false"
      :show-arrows="false"
      :show-indicators="false"
      :bg-color="'bg-[#EAEEF4]'"
    />
    <div class="w-full bg-[#F5F8FD]">
      <div class="min-h-[300px] md:min-h-[500px] w-full md:w-[1440px] mx-auto py-8 md:py-16 px-4 md:px-24">
        <div class="flex flex-col md:flex-row md:items-center gap-6 md:gap-8">
          <!-- 左侧功能图 -->
          <div class="w-full md:w-1/3">
            <img src="/images/order-01.png" class="w-full">
          </div>
          <!-- 右侧文案 -->
          <div class="w-full md:w-2/3 md:ml-8 space-y-6 md:space-y-8">
            <h2 class="text-xl md:text-2xl font-bold text-center md:text-left">产品概述</h2>
            <p class="text-sm md:text-base text-gray-600 leading-relaxed">
              商栈订货系统是专为企业间的熟客业务提供的交易平台，分为供货中心和订货中心两端。
            </p>
            
            <!-- 特点和价值部分 -->
            <div class="mt-6 md:mt-10 space-y-6 md:space-y-8">
              <!-- 基本特点 -->
              <div class="flex items-start">
                <div class="flex-shrink-0 mr-3 md:mr-4">
                  <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">1</div>
                </div>
                <div>
                  <h3 class="text-base md:text-lg font-medium mb-1 md:mb-2"><b>供货中心：</b></h3>
                  <p class="text-xs md:text-base text-gray-600">供货中心即商家端，供供货方使用。与传统电商不同的是，商栈供货中心可以对不同的客户展示不同的货品、设置不同的价格，推出不同的营销活动。</p>
                </div>
              </div>
              
              <!-- 核心价值 -->
              <div class="flex items-start">
                <div class="flex-shrink-0 mr-3 md:mr-4">
                  <div class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-blue-100 text-blue-500 font-bold text-xl md:text-2xl">2</div>
                </div>
                <div>
                  <h3 class="text-base md:text-lg font-medium mb-1 md:mb-2"><b>订货中心：</b></h3>
                  <p class="text-xs md:text-base text-gray-600">订货中心即买家端，供订货方使用。与市面上大部分封闭式订货系统不同的是，商栈订货中心是开放式的，订货方不需要供货方给予账号，不需要登录供货方的系统，而是类似电商的方式，在商栈平台上选择需要的供货方进行选购即可，并集中管理不同供货方的订单。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-[#FFFFFF]">
      <div class="w-full md:w-[1338px] mx-auto py-8 md:py-16 px-4 md:px-24">
          <p>
            <img src="/images/order-02.png" alt="业务流程图" class="w-full">
          </p>
      </div>
    </div>
    <div class="w-full bg-[#F5F8FD]">
      <div class="w-full md:w-[1440px] mx-auto py-8 md:py-16 px-4 md:px-24">
          <p>
            <img src="/images/order-flow.png" alt="业务流程图" class="w-full">
          </p>
      </div>
    </div>
    <div class="w-full bg-[#FFFFFF]">
      <div class="w-full md:w-[1440px] mx-auto py-4 md:py-8 px-4 md:px-24">
        <p>
            <img src="/images/order-foot-01.png" class="w-full">
          </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';

// 定义组件名称
defineComponent({
  name: 'OrderPage'
});

// 组件逻辑可以在这里添加
</script>

<style scoped>
.order-page {
  min-height: 100vh;
}

/* 移动端优化 */
@media (max-width: 768px) {
  .order-page img {
    max-width: 100%;
    height: auto;
  }
}
</style>
