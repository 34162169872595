<template>
  <div class="platform-page">
    <!-- 使用HomeBanner组件显示banner图 -->
    <HomeBanner 
      :images="['/images/qyhlxtpt-banner.png']"
      :autoplay="false"
      :show-arrows="false"
      :show-indicators="false"
    />
    <div class="w-full bg-[#F5F8FD]">
      <div class="min-h-[700px] md:w-[1440px] w-full mx-auto py-8 md:py-16 px-4 md:px-24">
        <div class="flex flex-col md:flex-row md:items-center md:gap-16">
          <!-- 左侧功能图 -->
          <div class="w-full md:w-1/2 mb-8 md:mb-0">
            <img src="/images/切图 55.png" alt="企业互联协同平台功能图" class="w-full">
          </div>
          <!-- 右侧文案 -->
          <div class="w-full md:w-1/2 space-y-6 md:space-y-8">
            <h2 class="text-xl md:text-2xl font-bold">产品概述</h2>
            <p class="text-gray-600 leading-relaxed text-sm md:text-base">
              商栈企业互联协同平台以传统的进销存系统和订货系统为基础功能，连接所有供应链伙伴，实现供应链企业间业务数据从系统到系统的直接传递，系统基础数据和业务数据的按需共享，业务往来过程中面向业务单据的场景化的高效沟通，以及其他各种业务场景的供应链协同需求。
            </p>
            <div class="space-y-4 md:space-y-6">
              <h3 class="text-lg md:text-xl font-bold">与传统企业软件不同的是：</h3>
              <div class="space-y-4 md:space-y-6">
                <div class="flex gap-2 md:gap-4">
                  <div class="text-2xl md:text-3xl font-bold text-blue-500 flex-shrink-0">1</div>
                  <p class="text-gray-600 text-sm md:text-base">商栈平台上的进销存系统、订货系统、供应链管理系统不是拼凑起来的一个个独立系统，而是融合为一体的，使<b>信息不再割裂，工作无需重复，流程更加顺畅，操作更加简单</b>。</p>
                </div>
                <div class="flex gap-2 md:gap-4">
                  <div class="text-2xl md:text-3xl font-bold text-blue-500 flex-shrink-0">2</div>
                  <p class="text-gray-600 text-sm md:text-base">商栈平台部署在阿里云上，用户只要<b>注册登录即可使用。无需安装，无需维护，系统使用不受时间与空间的限制</b>。</p>
                </div>
                <div class="flex gap-2 md:gap-4">
                  <div class="text-2xl md:text-3xl font-bold text-blue-500 flex-shrink-0">3</div>
                  <p class="text-gray-600 text-sm md:text-base">商栈平台<b>内嵌新手导航、帮助，以及菜单注释、页面注释、字段注释和按钮注释等功能，跟随式指导，可以帮助用户无需培训即可快速上手。边用边学，即学即用</b>。</p>
                </div>
                <div class="flex gap-2 md:gap-4">
                  <div class="text-2xl md:text-3xl font-bold text-blue-500 flex-shrink-0">4</div>
                  <p class="text-gray-600 text-sm md:text-base">主要功能均支持<b>PC端和移动端</b>两端的<b>同步操作</b>。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-white">
      <div class="md:min-h-[800px] w-full md:w-[1440px] mx-auto">
        <div class="container mx-auto px-4 md:px-8 py-8 md:py-12">
          <h1 class="text-xl md:text-2xl font-bold text-center mb-8 md:mb-12">主要模块</h1>
          <!-- 模块导航 - 修改为一排显示 -->
          <div class="flex flex-row justify-center items-center gap-2 md:gap-4 mx-auto max-w-4xl">
            <div v-for="(item, index) in modules" 
                 :key="index" 
                 class="group cursor-pointer px-1 md:px-2"
                 @click="activeModuleIndex = index"
                 :class="{'opacity-100': activeModuleIndex === index, 'opacity-60 hover:opacity-80': activeModuleIndex !== index}">
              <div :class="[
                'rounded-full w-10 h-10 md:w-14 md:h-14 mx-auto mb-2 flex items-center justify-center transition-all duration-300',
                activeModuleIndex === index ? 'bg-blue-700 scale-110' : 'bg-blue-600 hover:bg-blue-700',
                'text-white'
              ]">
                <i :class="['fas', item.icon, 'text-sm md:text-xl']"></i>
              </div>
              <p class="text-center text-gray-700 text-xs" 
                 :class="{'font-bold text-blue-700': activeModuleIndex === index}">
                {{ item.title }}
              </p>
            </div>
          </div>
          <!-- 模块详情图片展示区域 -->
          <div class="mt-8 md:mt-12 transition-all duration-500 ease-in-out">
            <div class="w-full">
              <img :src="`/images/product-menu-0${activeModuleIndex + 1}.png`" 
                   :alt="`${modules[activeModuleIndex].title}模块详情`" 
                   class="w-full transition-opacity duration-300">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-[#F5F8FD]">
      <div class="md:min-h-[1024px] w-full md:w-[1440px] mx-auto py-8 md:py-16 px-4 md:px-24">
        <div class="space-y-6 md:space-y-8">
          <h1 class="text-2xl md:text-4xl font-bold text-center">业务流程</h1>
          <p>
            <img src="/images/product-flow.png" alt="业务流程图" class="w-full">
          </p>
        </div>
      </div>
    </div>
    <div class="w-full" :style="{ backgroundImage: `url(/images/product-foot-bg.png)`, backgroundSize: 'cover', backgroundPosition: 'center' }">
      <div class="md:min-h-[913px] w-full md:w-[1440px] mx-auto px-4 md:px-24">
        <div class="space-y-6 md:space-y-8 py-8 md:py-0">
          <p>
            <img src="/images/product-foot.png" class="w-full">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, ref } from 'vue';
import HomeBanner from '@/components/home/HomeBanner.vue';

defineComponent({
  name: 'PlatformPage'
});

// 定义模块类型
const modules = ref([
  { title: '首页/工作台', icon: 'fa-home' },
  { title: '客户协同', icon: 'fa-users' },
  { title: '供应商协同', icon: 'fa-handshake' },
  { title: '数据协同', icon: 'fa-chart-pie' },
  { title: '系统协同', icon: 'fa-desktop' },
]);

// 当前激活的模块索引
const activeModuleIndex = ref(0);
</script>

<style scoped>
.platform-page {
  min-height: 100vh;
}
</style>
