<template>
  <div class="min-h-[500px] md:min-h-[700px] w-full md:w-[1440px] mx-auto bg-white pb-8">
    <div class="container mx-auto px-4 py-8 md:py-12">
      <!-- 标题部分 -->
      <h1 class="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12">核心痛点，一站解决</h1>
      <!-- 移动端单列布局 -->
      <div class="md:hidden flex flex-col space-y-6">
        <!-- 管理不善卡片 -->
        <div class="card border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="relative h-[240px] overflow-hidden">
            <img :src="managementImage" alt="管理不善" class="w-full h-full object-cover object-center">
            <div class="absolute inset-0 flex items-center justify-center">
              <img :src="hoverImage" alt="悬浮图片" class="max-w-[80%] max-h-[80%] z-10">
            </div>
          </div>
          <div class="card-content px-4 py-4">
            <h3 class="text-lg md:text-xl font-semibold mb-2">管理不善</h3>
            <div class="w-16 md:w-20 h-1 bg-[#00C853] mb-3 md:mb-4"></div>
            <p class="text-gray-600 text-sm md:text-base">契合中小微企业实际情况的管理工具，让管理变得简单、规范、高效。</p>
          </div>
        </div>
        <!-- 订单不足卡片 -->
        <div class="card border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="relative h-[240px] overflow-hidden">
            <img :src="orderImage" alt="订单不足" class="w-full h-full object-cover object-center">
            <div class="absolute inset-0 flex items-center justify-center">
              <img :src="hoverImage" alt="悬浮图片" class="max-w-[80%] max-h-[80%] z-10">
            </div>
          </div>
          <div class="card-content px-4 py-4">
            <h3 class="text-lg md:text-xl font-semibold mb-2">订单不足</h3>
            <div class="w-16 md:w-20 h-1 bg-[#00C853] mb-3 md:mb-4"></div>
            <p class="text-gray-600 text-sm md:text-base">营销工具+平台流量，让订单源源不断。</p>
          </div>
        </div>
        <!-- 资源不足卡片 -->
        <div class="card border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="relative h-[240px] overflow-hidden">
            <img :src="resourceImage" alt="资源不足" class="w-full h-full object-cover object-center">
            <div class="absolute inset-0 flex items-center justify-center">
              <img :src="hoverImage" alt="悬浮图片" class="max-w-[80%] max-h-[80%] z-10">
            </div>
          </div>
          <div class="card-content px-4 py-4">
            <h3 class="text-lg md:text-xl font-semibold mb-2">资源不足</h3>
            <div class="w-16 md:w-20 h-1 bg-[#00C853] mb-3 md:mb-4"></div>
            <p class="text-gray-600 text-sm md:text-base">平台大生态，让各类资源应有尽有。</p>
          </div>
        </div>
      </div>
      
      <!-- 桌面端三栏卡片布局 -->
      <div class="hidden md:grid grid-cols-3 gap-8">
        <!-- 管理不善卡片 -->
        <div class="card border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="relative h-[360px] overflow-hidden">
            <img :src="managementImage" alt="管理不善" class="w-full h-full object-cover object-center">
            <div class="absolute inset-0 flex items-center justify-center">
              <img :src="hoverImage" alt="悬浮图片" class="max-w-[80%] max-h-[80%] z-10">
            </div>
          </div>
          <div class="card-content px-6 py-6">
            <h3 class="text-xl font-semibold mb-2">管理不善</h3>
            <div class="w-20 h-1 bg-[#00C853] mb-4"></div>
            <p class="text-gray-600">契合中小微企业实际情况的管理工具，让管理变得简单、规范、高效。</p>
          </div>
        </div>
        <!-- 订单不足卡片 -->
        <div class="card border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="relative h-[360px] overflow-hidden">
            <img :src="orderImage" alt="订单不足" class="w-full h-full object-cover object-center">
            <div class="absolute inset-0 flex items-center justify-center">
              <img :src="hoverImage" alt="悬浮图片" class="max-w-[80%] max-h-[80%] z-10">
            </div>
          </div>
          <div class="card-content px-6 py-6">
            <h3 class="text-xl font-semibold mb-2">订单不足</h3>
            <div class="w-20 h-1 bg-[#00C853] mb-4"></div>
            <p class="text-gray-600">营销工具+平台流量，让订单源源不断。</p>
          </div>
        </div>
        <!-- 资源不足卡片 -->
        <div class="card border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div class="relative h-[360px] overflow-hidden">
            <img :src="resourceImage" alt="资源不足" class="w-full h-full object-cover object-center">
            <div class="absolute inset-0 flex items-center justify-center">
              <img :src="hoverImage" alt="悬浮图片" class="max-w-[80%] max-h-[80%] z-10">
            </div>
          </div>
          <div class="card-content px-6 py-6">
            <h3 class="text-xl font-semibold mb-2">资源不足</h3>
            <div class="w-20 h-1 bg-[#00C853] mb-4"></div>
            <p class="text-gray-600">平台大生态，让各类资源应有尽有。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent } from 'vue';

defineComponent({
  name: 'HomeCases'
});

const managementImage = 'images/hexintongdian01.png';
const orderImage = '/images/hexintongdian02.png';
const resourceImage = '/images/hexintongdian03.png';
const hoverImage = '/images/hexintongdian0.png';
</script>

<style scoped>
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 1rem;
  }
}
</style>
